import React from "react"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import * as v from "../../components/variables"
import SEO from "../../components/seo"

import Header from "../../components/Header"
import NavList from "../../components/NavList"
import NavListItem from "../../components/NavList/item"

import CardList from "../../components/CardList"
import Card from "../../components/CardList/card"

const Container = styled.div`
  ${v.grid}

  h2 {
    ${v.heading.c};
    grid-column: main;
    color: ${v.purple};
  }

  &:after {
    content: "";
    display: block;
    grid-column: full;
    height: ${v.baseline(2)};
  }
`

const Mode = ({ data, pageContext }) => {
  const therapy = data.therapy?.name.value
  const mode = data.mode?.name.value
  const headerImage = data.mode?.header?.value.url
  const description = data.mode?.description?.value
  const guides = data.mode?.guides?.value
  const crumb = mode.crumb?.value

  const related = data.mode?.related?.value
  const links = data.mode?.links?.value.map(link => link.value)

  return (
    <Layout>
      <SEO title={mode + " " + therapy} />
      <Container>
        <Header
          image={headerImage}
          title={mode}
          description={description}
          breadcrumb={pageContext.breadcrumb}
          crumb={crumb}
        ></Header>
        <NavList>
          {guides?.map(guide => (
            <NavListItem
              key={guide.title.slug}
              link={
                "/" +
                data.therapy?.name.slug +
                "/" +
                data.mode?.name.slug +
                "/" +
                guide.title.slug
              }
              label={guide.title.value}
            ></NavListItem>
          ))}
        </NavList>

        {links && (
          <>
            <NavList title="Further reading:">
              {links?.map(link => (
                <NavListItem
                  key={link.name.value}
                  link={link.url.value}
                  label={link.name.value}
                ></NavListItem>
              ))}
            </NavList>
          </>
        )}

        {related && (
          <>
            <CardList title="Other modes:">
              {related &&
                related.map(mode => (
                  <Card
                    image={mode.icon?.value.url}
                    key={mode.name.slug}
                    link={
                      "/" +
                      mode.therapy?.value.name?.slug +
                      "/" +
                      mode.name.slug
                    }
                    label={mode.name.value}
                  ></Card>
                ))}
            </CardList>
          </>
        )}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($modeId: String, $therapyId: String) {
    therapy: cockpitTherapy(cockpitId: { eq: $therapyId }) {
      cockpitId
      name {
        value
        slug
      }
    }

    mode: cockpitMode(cockpitId: { eq: $modeId }) {
      cockpitId
      name {
        value
        slug
      }
      crumb {
        value
      }
      header {
        value {
          url
        }
      }
      guides {
        value {
          cockpitId
          title {
            value
            slug
          }
        }
      }
      links {
        value {
          value {
            name {
              value
            }
            url {
              value
            }
          }
        }
      }
      related {
        value {
          cockpitId
          name {
            slug
            value
          }
          icon {
            value {
              url
            }
          }
          therapy {
            value {
              name {
                slug
              }
            }
          }
        }
      }
    }

    guides: allCockpitGuide(
      filter: {
        therapy: { value: { cockpitId: { eq: $therapyId } } }
        mode: { value: { cockpitId: { eq: $modeId } } }
      }
    ) {
      edges {
        node {
          cockpitId
          title {
            value
            slug
          }
        }
      }
    }
  }
`

export default Mode
