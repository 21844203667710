import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import * as v from "../variables"

const Container = styled.li`
  a {
    ${v.heading.c}
    display: block;
    text-decoration: none;
    border: 1px solid rgb(0 0 0 / 0.2);
    color: black;
    transition: border-color ${v.speed.fast};
    display: grid;
    grid-template-columns: 2fr 1fr 36px;
    background-color: white;
    text-align: right;
    align-items: center;

    &:after {
      content: "›";
      ${v.heading.b};
      color: ${v.purple};
      margin-right: ${v.baseline()};
    }

    /* &:visited {
      border-color: #fdfaff;
    } */
    &:hover {
      border-color: ${v.purple};
    }

    img {
      height: ${v.baseline(10)};
      width: 100%;
      object-fit: contain;
      outline: none;
      /* background: #f2f2f2; */
    }
  }
`

const Card = ({ link, label, image }) => (
  <Container>
    <Link to={link}>
      {/* {image && <img src={image}></img>} */}
      <img src={image}></img>
      <span>{label}</span>
    </Link>
  </Container>
)

export default Card
