import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import * as v from "../variables"

const Container = styled.div`
  grid-column: main;

  grid-gap: inherit;

  h2 {
    ${v.heading.c};
    color: ${v.purple};
    margin-bottom: ${v.baseline()};
  }

  .cards {
    display: grid;
    grid-gap: inherit;
    @media all and (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: ${v.baseline(2)};
    }
  }
`

const CardList = ({ children, title }) => (
  <Container>
    {title && <h2>{title}</h2>}
    <ul className="cards">{children}</ul>
  </Container>
)

export default CardList
